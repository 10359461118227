.onBoard{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.onBoardTitle {
    text-align: center;
    padding: 20px 0;
    font-size: 40px;
    
}

.onBoardContainer {
    padding: 0 20px;
    flex: 1;
    height: 100%;
}
